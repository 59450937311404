/* eslint-disable @typescript-eslint/no-explicit-any */

import { clearDasherToken, clearTrelloToken } from '@dasher-react/components'
import { type Trello } from '../types/trello'
import { PUP_ID } from '../config'

export async function getRemoveData (
  t: Trello.PowerUp.IFrame,
): Promise<void> {
  await clearTrelloToken(t, PUP_ID)
  await clearDasherToken(t)
}
