import { CapabilityProps, DateFilterOptions, DateFormatOptions, Preferences, TickIntervalOptions } from "./types/power-up"
import { Trello } from "./types/trello"

const PUP_DISPLAY_NAME = "Mermaid Gantt Power-Up"
const PUP_ID = "5"
const TICK_INTERVAL_OPTIONS: TickIntervalOptions[] = ["auto", "day", "week", "month"]

const DEFAULT_PREFERENCES: Preferences = {
  tickInterval: TICK_INTERVAL_OPTIONS[0],
  axisFormat: "YYYY-MM-DD",
  todayMarker: true,
  excludeWeekends: false,
  zoom: 100,
  hideCompleted: false,
  dateFilter: "all dates",
  orderBy: "date"
}

const TASKBAR_COLOR = "#FBBF24"
const TASKBAR_DONE_COLOR = "#34D399"

const DATE_FILTER_OPTIONS: DateFilterOptions[] = [
  "all dates",
  "due today",
  "due this week",
  "due this month",
  "due this year"
]

const DATE_FORMAT_OPTIONS: string[] = [
  "YYYY-MM-DD",
  "YYYY-DD-MM",
  "MM-DD-YYYY",
  "DD-MM-YYYY",
  "MM-DD-YY",
  "DD-MM-YY"
]

const DATE_FORMAT_DATA_MAP: Record<DateFormatOptions, string> = {
  "YYYY-MM-DD": "%Y-%m-%d",
  "YYYY-DD-MM": "%y-%d-%m",
  "MM-DD-YYYY": "%m-%d-%Y",
  "DD-MM-YYYY": "%d-%m-%Y",
  "MM-DD-YY": "%m-%d-%y",
  "DD-MM-YY": "%d-%m-%y"
}

//https://tailwindcolor.com/
const THEME_CSS = `
* { font-family: ui-sans-serif, system-ui, sans-serif !important; }
.grid .tick { color: #ddd6fe; shape-rendering: crispEdges; }
.grid .tick text { fill: #000000; font-size: 0.75rem; }
.task0 { stroke: ${TASKBAR_COLOR}; fill: ${TASKBAR_COLOR}; }
.task1 { stroke: ${TASKBAR_COLOR}; fill: ${TASKBAR_COLOR}; }
.task2 { stroke: ${TASKBAR_COLOR}; fill: ${TASKBAR_COLOR}; }
.task3 { stroke: ${TASKBAR_COLOR}; fill: ${TASKBAR_COLOR}; }
.done0 { stroke: ${TASKBAR_DONE_COLOR}; fill: ${TASKBAR_DONE_COLOR}; }
.done1 { stroke: ${TASKBAR_DONE_COLOR}; fill: ${TASKBAR_DONE_COLOR}; }
.done2 { stroke: ${TASKBAR_DONE_COLOR}; fill: ${TASKBAR_DONE_COLOR}; }
.done3 { stroke: ${TASKBAR_DONE_COLOR}; fill: ${TASKBAR_DONE_COLOR}; }
.sectionTitle { font-style: italic; font-size: 0.75rem; }
.section1 { fill: #FEF08A; opacity: 0.2; }
.section0 { fill: white; }
.section3 { fill: #FEF08A; opacity: 0.2;}
.section2 { fill: white; }
`

const CAPABILITY_PROPS: CapabilityProps = {
  baseUrl: window.location.href.replace(/\/$/, ""),
  logo: {
    dark: "/static/mermaid-icon.svg",
    light: "/static/mermaid-icon.svg"
  },
  icon: {
    dark: "/static/mermaid-icon.svg",
    light: "/static/mermaid-icon.svg"
  },
  authorizeIcon: {
    dark: "/static/authorize-icon.svg",
    light: "/static/authorize-icon.svg"
  },
  upgradeIcon: {
    dark: "/static/upgrade-icon.svg",
    light: "/static/upgrade-icon.svg"
  }
}

const HELP_ACTION = {
  icon: "https://trello.bluedashersoftware.com/static/help.svg",
  alt: "help",
  position: "right" as "right" | "left",
  callback: (t: Trello.PowerUp.IFrame) => {
    return t.popup({
      title: "Help Center",
      url: "./help.html",
      height: 300
    })
  }
}

export {
  PUP_DISPLAY_NAME,
  PUP_ID,
  TICK_INTERVAL_OPTIONS,
  DEFAULT_PREFERENCES,
  THEME_CSS,
  DATE_FILTER_OPTIONS,
  DATE_FORMAT_OPTIONS,
  DATE_FORMAT_DATA_MAP,
  CAPABILITY_PROPS,
  HELP_ACTION
}
