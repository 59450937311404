/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type Trello } from "../types/trello"
import { type CapabilityProps } from "../types/power-up"
import { getAuthorizationStatus } from "../authorization-status/capability"
import { HELP_ACTION } from "../config"

export async function getBoardButton(
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<Trello.PowerUp.BoardButtonCallback[]> {
  const icon = {
    dark: props.baseUrl + props.icon.dark,
    light: props.baseUrl + props.icon.light
  }

  const text = "Mermaid Gantt"
  const { authorized } = await getAuthorizationStatus(t)

  return [
    {
      icon,
      text,
      callback: (t: Trello.PowerUp.IFrame) => {
        return t.modal({
          title: `Mermaid Gantt Power-Up`,
          url: "./onboarding.html",
          accentColor: "#ede9fe",
          height: !authorized ? 400 : 600,
          fullscreen: authorized ? true : false,
          actions: [HELP_ACTION]
        })
      },
      condition: "edit"
    }
  ]
}
