import { type Trello } from "../types/trello"
import { getAuthorizationStatus } from "../authorization-status/capability"
import { HELP_ACTION } from "../config"

export async function getOnEnable(t: Trello.PowerUp.IFrame): Promise<void> {
  const { authorized } = await getAuthorizationStatus(t)

  return t.modal({
    title: `Mermaid Gantt Power-Up`,
    url: "./onboarding.html",
    accentColor: "#ede9fe",
    height: !authorized ? 400 : 600,
    fullscreen: authorized ? true : false,
    actions: [HELP_ACTION]
  })
}
